<template>
  <div class="dev">
    <main-card class="card">
      <base-datepicker-filter
        :periods="periods"
        :value="calendarValue"
        @change="onCalendarChange"
      />
    </main-card>
    <main-card class="card">
      <base-collapser :summary="{ end: '+ ' + this.$n(15000, 'currency') }" class="collapser">
        Content
      </base-collapser>
      <base-collapser-create-server theme="plain" :summary="{ more: 'Титул' }" class="collapser">
        Content
      </base-collapser-create-server>
      <base-collapser :summary="{ end: '- ' + this.$n(150000, 'currency') }" class="collapser">
        Content
      </base-collapser>
      <base-collapser class="collapser"> Content </base-collapser>
    </main-card>
    <main-card class="card">
      <base-collapser theme="plain" reverse class="collapser"> Content </base-collapser>
    </main-card>
  </div>
</template>

<script>
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter';
import BaseCollapser from '@/components/BaseCollapser/BaseCollapser.vue';
import BaseCollapserCreateServer from '@/components/BaseCollapser/BaseCollapserCreateServer.vue';
export default {
  name: 'DevSandboxIndex',
  components: { BaseCollapserCreateServer, BaseDatepickerFilter, BaseCollapser },
  data() {
    return {
      calendarValue: { start: new Date(), end: new Date() },
      periods: ['week', 'month', 'quarter', 'year'],
    };
  },
  methods: {
    onCalendarChange(val) {
      this.calendarValue = val;
    },
  },
};
</script>

<style lang="stylus" scoped>
.card {
  max-width: 600px;
  & + & {
    margin-top: 2rem;
  }
}
</style>
